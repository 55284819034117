import * as React from "react";

import Layout from "../components/layout";

export default class extends React.Component<{}, {}> {
  public render() {
    return (
      <Layout>
        <section>
          <div className="container my-5">
            <div className="row">
              <div className="col-lg-12">
                <p>
                  Common Factor GmbH ("us", "we", or "our") operates the
                  https://www.common-factor.com website (the "Service").
                </p>

                <p>
                  This page informs you of our policies regarding the
                  collection, use, and disclosure of personal data when you use
                  our Service and the choices you have associated with that
                  data.
                </p>

                <p>
                  We use your data to provide and improve the Service. By using
                  the Service, you agree to the collection and use of
                  information in accordance with this policy. Unless otherwise
                  defined in this Privacy Policy, terms used in this Privacy
                  Policy have the same meanings as in our Terms and Conditions,
                  accessible from
                  <a href="https://www.common-factor.com">
                    www.common-factor.com
                  </a>
                  .
                </p>

                <h2>Definitions</h2>

                <h3>Personal Data</h3>
                <p>
                  Personal Data means data about a living individual who can be
                  identified from those data (or from those and other
                  information either in our possession or likely to come into
                  our possession).
                </p>

                <h3>Usage Data</h3>
                <p>
                  Usage Data is data collected automatically either generated by
                  the use of the Service or from the Service infrastructure
                  itself (for example, the duration of a page visit).
                </p>

                <h3>Cookies</h3>
                <p>
                  Cookies are small pieces of data stored on a User’s device.
                </p>

                <h3>Data Controller</h3>
                <p>
                  Data Controller means a person who (either alone or jointly or
                  in common with other persons) determines the purposes for
                  which and the manner in which any personal data are, or are to
                  be, processed.
                </p>
                <p>
                  For the purpose of this Privacy Policy, we are a Data
                  Controller of your data.
                </p>

                <h3>Data Processor (or Service Providers)</h3>
                <p>
                  Data Processor (or Service Provider) means any person (other
                  than an employee of the Data Controller) who processes the
                  data on behalf of the Data Controller.
                </p>
                <p>
                  We may use the services of various Service Providers in order
                  to process your data more effectively.
                </p>

                <h3>Data Subject</h3>
                <p>
                  Data Subject is any living individual who is the subject of
                  Personal Data.
                </p>

                <h3>User</h3>
                <p>
                  The User is the individual using our Service. The User
                  corresponds to the Data Subject, who is the subject of
                  Personal Data.
                </p>

                <h2>Information Collection And Use</h2>
                <p>
                  We collect several different types of information for various
                  purposes to provide and improve our Service to you.
                </p>

                <h3>Types of Data Collected</h3>
                <h4>Personal Data</h4>
                <p>
                  While using our Service, we may ask you to provide us with
                  certain personally identifiable information that can be used
                  to contact or identify you ("Personal Data"). Personally
                  identifiable information may include, but is not limited to:
                </p>
                <ul>
                  <li>Email address</li>
                  <li>First name and last name</li>
                  <li>Phone number</li>
                  <li>Address, State, Province, ZIP/Postal code, City</li>
                  <li>Cookies and Usage Data</li>
                </ul>

                <p>
                  We may use your Personal Data to contact you with newsletters,
                  marketing or promotional materials and other information that
                  may be of interest to you. You may opt out of receiving any,
                  or all, of these communications from us by following the
                  unsubscribe link or instructions provided in any email we
                  send.
                </p>

                <h4>Usage Data</h4>
                <p>
                  We may also collect information how the Service is accessed
                  and used ("Usage Data"). This Usage Data may include
                  information such as your computer's Internet Protocol address
                  (e.g. IP address), browser type, browser version, the pages of
                  our Service that you visit, the time and date of your visit,
                  the time spent on those pages, unique device identifiers and
                  other diagnostic data.
                </p>

                <h4>Location Data</h4>
                <p>
                  We may use and store information about your location if you
                  give us permission to do so (“Location Data”). We use this
                  data to provide features of our Service, to improve and
                  customize our Service.
                </p>
                <p>
                  You can enable or disable location services when you use our
                  Service at any time, through your device settings.
                </p>

                <h4>Tracking &amp; Cookies Data</h4>
                <p>
                  We use cookies and similar tracking technologies to track the
                  activity on our Service and hold certain information.
                </p>
                <p>
                  Cookies are files with small amount of data which may include
                  an anonymous unique identifier. Cookies are sent to your
                  browser from a website and stored on your device. Tracking
                  technologies also used are beacons, tags, and scripts to
                  collect and track information and to improve and analyze our
                  Service.
                </p>
                <p>
                  You can instruct your browser to refuse all cookies or to
                  indicate when a cookie is being sent. However, if you do not
                  accept cookies, you will not be able to use our Service as we
                  will no longer know whether you are a logged in user.
                </p>

                <p>Examples of Cookies we use:</p>
                <ul>
                  <li>
                    <strong>Session Cookies.</strong>
                    We use Session Cookies to operate our Service.
                  </li>
                  <li>
                    <strong>Preference Cookies.</strong>
                    We use Preference Cookies to remember your preferences and
                    various settings.
                  </li>
                  <li>
                    <strong>Security Cookies.</strong>
                    We use Security Cookies for security purposes.
                  </li>
                </ul>

                <h2>Use of Data</h2>

                <p>
                  Common Factor GmbH uses the collected data for various
                  purposes:
                </p>
                <ul>
                  <li>To provide and maintain our Service</li>
                  <li>To notify you about changes to our Service</li>
                  <li>
                    To allow you to participate in interactive features of our
                    Service when you choose to do so
                  </li>
                  <li>To provide customer support</li>
                  <li>
                    To gather analysis or valuable information so that we can
                    improve our Service
                  </li>
                  <li>To monitor the usage of our Service</li>
                  <li>To detect, prevent and address technical issues</li>
                  <li>
                    To provide you with news, special offers and general
                    information about other goods, services and events which we
                    offer that are similar to those that you have already
                    purchased or enquired about unless you have opted not to
                    receive such information
                  </li>
                </ul>

                <h2>Retention of Data</h2>
                <p>
                  Common Factor GmbH will retain your Personal Data only for as
                  long as is necessary for the purposes set out in this Privacy
                  Policy. We will retain and use your Personal Data to the
                  extent necessary to comply with our legal obligations (for
                  example, if we are required to retain your data to comply with
                  applicable laws), resolve disputes, and enforce our legal
                  agreements and policies.
                </p>
                <p>
                  Common Factor GmbH will also retain Usage Data for internal
                  analysis purposes. Usage Data is generally retained for a
                  shorter period of time, except when this data is used to
                  strengthen the security or to improve the functionality of our
                  Service, or we are legally obligated to retain this data for
                  longer time periods.
                </p>

                <h2>Transfer Of Data</h2>
                <p>
                  Your information, including Personal Data, may be transferred
                  to — and maintained on — computers located outside of your
                  state, province, country or other governmental jurisdiction
                  where the data protection laws may differ than those from your
                  jurisdiction.
                </p>
                <p>
                  If you are located outside Germany and choose to provide
                  information to us, please note that we transfer the data,
                  including Personal Data, to Germany and process it there.
                </p>
                <p>
                  Your consent to this Privacy Policy followed by your
                  submission of such information represents your agreement to
                  that transfer.
                </p>
                <p>
                  Common Factor GmbH will take all steps reasonably necessary to
                  ensure that your data is treated securely and in accordance
                  with this Privacy Policy and no transfer of your Personal Data
                  will take place to an organization or a country unless there
                  are adequate controls in place including the security of your
                  data and other personal information.
                </p>

                <h2>Disclosure Of Data</h2>

                <h3>Disclosure for Law Enforcement</h3>
                <p>
                  Under certain circumstances, Common Factor GmbH may be
                  required to disclose your Personal Data if required to do so
                  by law or in response to valid requests by public authorities
                  (e.g. a court or a government agency).
                </p>

                <h3>Legal Requirements</h3>
                <p>
                  Common Factor GmbH may disclose your Personal Data in the good
                  faith belief that such action is necessary to:
                </p>
                <ul>
                  <li>To comply with a legal obligation</li>
                  <li>
                    To protect and defend the rights or property of Common
                    Factor GmbH
                  </li>
                  <li>
                    To prevent or investigate possible wrongdoing in connection
                    with the Service
                  </li>
                  <li>
                    To protect the personal safety of users of the Service or
                    the public
                  </li>
                  <li>To protect against legal liability</li>
                </ul>

                <h2>Security Of Data</h2>
                <p>
                  The security of your data is important to us, but remember
                  that no method of transmission over the Internet, or method of
                  electronic storage is 100% secure. While we strive to use
                  commercially acceptable means to protect your Personal Data,
                  we cannot guarantee its absolute security.
                </p>
                <p>
                  We strongly recommend always logging out of our Service after
                  you have finished using it, particularly when using a public
                  computer.
                </p>

                <h2>"Do Not Track" Signals</h2>
                <p>
                  We do not support Do Not Track ("DNT"). Do Not Track is a
                  preference you can set in your web browser to inform websites
                  that you do not want to be tracked.
                </p>
                <p>
                  You can enable or disable Do Not Track by visiting the
                  Preferences or Settings page of your web browser.
                </p>

                <h2>Your Rights</h2>
                <p>
                  Common Factor GmbH aims to take reasonable steps to allow you
                  to correct, amend, delete, or limit the use of your Personal
                  Data.
                </p>
                <p>
                  Whenever made possible, you can update your Personal Data
                  directly within your account settings section. If you are
                  unable to change your Personal Data, please contact us at
                  hello@common-factor.com to make the required changes for you.
                </p>
                <p>
                  If you wish to be informed what Personal Data we hold about
                  you and if you want it to be removed from our systems, feel
                  free to contact us at the same email address.
                </p>
                <p>In certain circumstances, you have the right:</p>
                <ul>
                  <li>
                    To access and receive a copy of the Personal Data we hold
                    about you
                  </li>
                  <li>
                    To rectify any Personal Data held about you that is
                    inaccurate
                  </li>
                  <li>
                    To request the deletion of Personal Data held about you
                  </li>
                </ul>
                <p>
                  You have the right to data portability for the information you
                  provide to Common Factor GmbH. You can request to obtain a
                  copy of your Personal Data in a commonly used electronic
                  format so that you can manage and move it.
                </p>
                <p>
                  Please note that we may ask you to verify your identity before
                  responding to such requests.
                </p>

                <h2>Service Providers</h2>
                <p>
                  We may employ third party companies and individuals to
                  facilitate our Service ("Service Providers"), to provide the
                  Service on our behalf, to perform Service-related services or
                  to assist us in analyzing how our Service is used.
                </p>
                <p>
                  These third parties have access to your Personal Data only to
                  perform these tasks on our behalf and are obligated not to
                  disclose or use it for any other purpose.
                </p>

                <h3>Analytics</h3>
                <p>
                  We may use third-party Service Providers to monitor and
                  analyze the use of our Service.
                </p>

                <ul>
                  <li>
                    <p>
                      <strong>Google Analytics</strong>
                    </p>
                    <p>
                      Google Analytics is a web analytics service offered by
                      Google that tracks and reports website traffic. We use
                      their tools to track which parts of our website are used
                      frequently and by which browsers etc. so that we can
                      improve our website. Google may use the collected data to
                      contextualize and personalize the ads of its own
                      advertising network.
                    </p>
                    <p>
                      You can opt-out of having made your activity on the
                      Service available to Google Analytics by installing the
                      Google Analytics opt-out browser add-on. The add-on
                      prevents the Google Analytics JavaScript (ga.js,
                      analytics.js, and dc.js) from sharing information with
                      Google Analytics about visits activity.
                    </p>
                    <p>
                      For more information on the privacy practices of Google,
                      please visit the Google Privacy &amp; Terms web page:
                      http://www.google.com/intl/en/policies/privacy/
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Bugsnag Inc.</strong>
                    </p>
                    <p>
                      Bugsnag is a service for automatically collecting usage
                      and issue reports from our Service; e.g. when an error
                      occurs or a page is broken on our end, their service logs
                      the information so that our developers can see where in
                      our service the issue occurred, when and which user was
                      affected. To do this, Bugsnag stores the affected area of
                      our platform, the affected user (email address) and the
                      date/time, along with some technical information for our
                      developers (such as which of our code was affected), so
                      that not only we can fix the issue but also follow up on
                      affected clients.
                    </p>
                    <p>
                      For more information on the privacy practices of Bugsnag,
                      please visit their web page:
                      https://docs.bugsnag.com/legal/privacy-policy/
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>HotJar Ltd.</strong>
                    </p>
                    <p>
                      HotJar helps us to understand how people are using our
                      platform and website by collecting usage data so that we
                      can see which parts of the platform have been used
                      (buttons clicked, pages opened) and how, making it
                      possible to optimize the usability. To be able to identify
                      which population (e.g. trainer, student, administrator)
                      uses the platform in what way, we identify users by email
                      address.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>MailChimp</strong>
                    </p>
                    <p>
                      MailChimp is a company that provides newsletter delivery
                      software; meaning when you opt in to one of our
                      newsletters, we provide MailChimp with your email address
                      so that they can send our newsletter content on our
                      behalf.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>SurveyMonkey Europe UC</strong>
                    </p>
                    <p>
                      If customers opted in to receive surveys from us to learn
                      more about their feedback on our platform, we are
                      assigning SurveyMonkey to send emails using the customer's
                      email address to them.
                    </p>
                  </li>
                </ul>

                <h3>Other</h3>
                <p>
                  Aside from payment processors and analytical tools, we also
                  use the following Service Providers:
                </p>
                <ul>
                  <li>
                    <p>
                      <strong>Amazon Web Services, Inc.</strong>
                    </p>
                    <p>
                      Parts of our platform are hosted by Amazon Web Services.
                      They are Amazon's company for hosting services (e.g. so
                      that websites like ours are available from within the
                      internet), where we store encrypted customer data like
                      uploaded materials and everything else we need to actually
                      deliver our services.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Freshworks Inc.</strong>
                    </p>
                    <p>
                      When users or visitors reach out to us to get support or
                      ask a question - be it via email or an online support
                      form, their request along with their contact data is
                      stored at Freshworks. This is a specialized tool for
                      service desks helping us to manage who sent which question
                      and when, and to improve our customer support, similarly
                      to an email app, but more specialized. To give our
                      customer support staff helpful insight when a user reaches
                      out, Freshworks also stores whether someone is a customer
                      or not, as well as their plan.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Google</strong>
                    </p>
                    <p>
                      Parts of our platform are hosted by Google Cloud. Similar
                      to Amazon Web Services, Inc., Google Cloud is a hosting
                      service, where we store encrypted customer data like
                      uploaded materials and everything else we need to actually
                      deliver our services.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Microsoft</strong>
                    </p>
                    <p>
                      Parts of our platform are hosted by Microsoft Azure.
                      Similar to Google Cloud, Azure is a hosting service, where
                      we store encrypted customer data like uploaded materials
                      and everything else we need to actually deliver our
                      services.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Twilio, Inc.</strong>
                    </p>
                    <p>
                      Similar to what Amazon Web Service, Google Cloud and
                      Microsoft are for websites, Twilio is for phone calls.
                      They are providing us with phone numbers for dialing in
                      and other connectivity services to make our conference
                      calls used for online meetings possible; to do so, they
                      store caller IDs (to put people into the correct
                      conference) and IP addresses (for VoIP calls) on our
                      behalf, along with the date and time of a call.
                    </p>
                  </li>
                </ul>

                <h2>Links To Other Sites</h2>
                <p>
                  Our Service may contain links to other sites that are not
                  operated by us. If you click on a third party link, you will
                  be directed to that third party's site. We strongly advise you
                  to review the Privacy Policy of every site you visit.
                </p>
                <p>
                  We have no control over and assume no responsibility for the
                  content, privacy policies or practices of any third party
                  sites or services.
                </p>

                <h2>Children's Privacy</h2>
                <p>
                  Our Service does not address anyone under the age of 13
                  ("Children").
                </p>
                <p>
                  We do not knowingly collect personally identifiable
                  information from anyone under the age of 13. If you are a
                  parent or guardian and you are aware that your Children has
                  provided us with Personal Data, please contact us. If we
                  become aware that we have collected Personal Data from
                  children without verification of parental consent, we take
                  steps to remove that information from our servers.
                </p>

                <h2>Changes To This Privacy Policy</h2>
                <p>
                  We may update our Privacy Policy from time to time. We will
                  notify you of any changes by posting the new Privacy Policy on
                  this page.
                </p>
                <p>
                  We will let you know via email and/or a prominent notice on
                  our Service, prior to the change becoming effective and update
                  the "effective date" at the top of this Privacy Policy.
                </p>
                <p>
                  You are advised to review this Privacy Policy periodically for
                  any changes. Changes to this Privacy Policy are effective when
                  they are posted on this page.
                </p>

                <h2>Contact Us</h2>
                <p>
                  If you have any questions about this Privacy Policy, please
                  contact us:
                </p>
                <ul>
                  <li>By email: hello@common-factor.com</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
