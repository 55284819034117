import { Link } from "gatsby";
import React from "react";
import logo from "../images/logo.png";

const Footer = () => (
  <footer className="text-gray-700 body-font">
    <div className="container px-5 py-8 mx-auto flex items-center sm:flex-row flex-col">
      <a className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
        <img src={logo} alt="Logo" style={{ maxWidth: 30 }} />
        <span className="ml-3 text-xl" style={{ fontFamily: "Nunito" }}>
          common factor
        </span>
      </a>
      <p className="text-sm text-gray-500 sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-200 sm:py-2 sm:mt-0 mt-4">
        © {new Date().getFullYear()} Common Factor GmbH —
        <span className="text-gray-600 ml-1">
          Stresemannstr. 13, 51149 Köln, info@common-factor.com
          <br />
          CEO Markus Hanslik, UStID DE322900256, HRB 96882, Amtsgericht Köln
        </span>
      </p>
      <span className="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
        <Link className="text-gray-500" to="/privacy-policy">
          Privacy Policy
        </Link>
      </span>
    </div>
  </footer>
);

export default Footer;
