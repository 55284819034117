import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import logo from "../images/logo.png";

const Header = ({ siteTitle }: { siteTitle: string }) => (
  <header className="text-gray-700 body-font">
    <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
      {/*<nav className="flex lg:w-2/5 flex-wrap items-center text-base md:ml-auto">
        <a className="mr-5 hover:text-gray-900">First Link</a>
        <a className="hover:text-gray-900">Fourth Link</a>
        </nav>*/}
      <Link
        className="flex order-first lg:order-none lg:w-1/5 title-font font-medium items-center text-gray-900 lg:items-center lg:justify-center mb-4 md:mb-0 mx-auto"
        to="/"
        style={{
          textDecoration: `none`,
        }}
      >
        <img src={logo} alt="Logo" style={{ maxWidth: 30 }} />
        <span className="ml-3 text-xl" style={{ fontFamily: "Nunito" }}>
          common factor
        </span>
      </Link>
      {/*<div className="lg:w-2/5 inline-flex lg:justify-end ml-5 lg:ml-0">
        <button className="inline-flex items-center bg-gray-200 border-0 py-1 px-3 focus:outline-none hover:bg-gray-300 rounded text-base mt-4 md:mt-0">
          Button
          <svg
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            className="w-4 h-4 ml-1"
            viewBox="0 0 24 24"
          >
            <path d="M5 12h14M12 5l7 7-7 7"></path>
          </svg>
        </button>
      </div>*/}
    </div>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
